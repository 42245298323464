import { GameGuideProps } from "../types";
import "./GameGuide.scss";

function GameGuide(props: GameGuideProps) {
  return (
    <pre className={props.visible ? "itw-gameguide" : "itw-gameguide-hidden"}>
      {props.guide}
    </pre>
  );
}

export default GameGuide;
