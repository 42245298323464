import games from "../data/games.json";
import { Game } from "../types";


export const GameEntryList = games as Game[];
const GameEntriesIndexMap = {} as { [key: string]: number };
GameEntryList.forEach((gameEntry, index) => { GameEntriesIndexMap[gameEntry.id] = index; })

export function getGameEntryById(gameId: string) {
    const gameIndex = GameEntriesIndexMap[gameId];
    return GameEntryList[gameIndex];
}