export interface HeaderProps {
    device: Device;
}

export interface Game {
    id: string;
    name: Record<string, string>;
    author: string;
    authorAvatar: string;
    desc: Record<string, string>;
    tags: string[];
    videoLinks: { youtube: string; bilibili: string };
    screenshots: string[];
    guide: Record<string, string>;
    details: Record<string, string>;
}

export interface GameGuideProps {
    setShowGameGuide: (showGameGuide: boolean) => void;
    visible: boolean;
    guide: string;
}

export interface GameInfoPageProps {
    visible: boolean;
    videoLinks: { youtube: string; bilibili: string };
    screenshots: string[];
    details: string;
}
export interface GameLaunchPadProps {
    gameId: string;
    showGameLaunchPad: boolean;
    setShowGameLaunchPad: (visible: boolean) => void;
}
export interface FooterBarProps {
    gameId: string;
    gameGuideVisible: boolean;
    setShowGameScreen: (showGameScreen: boolean) => void;
    setShowGameLaunchPad: (showGameLaunchPad: boolean) => void;
    setShowGameGuide: (showGameGuide: boolean) => void;
}
export interface GameListProps {
    device: Device;
}

export interface GameGridProps {
    device: Device;
}

export interface GameScreenProps {
    device: Device;
    gameId: string;
}

export interface VideoContainerProps {
    videoLinks: { youtube: string; bilibili: string }
}

export enum Device {
    Phone, Desktop, Tablet, LargeScreen
}

export enum VideoPlatform {
    youtube = "youtube", bilibili = "bilibili"
}
