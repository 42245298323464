import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nEN from './i18n.en.json';
import i18nZH from './i18n.zh.json';
import detector from "i18next-browser-languagedetector";

export const initI18n = (): (() => i18n) => {
    i18next.use(detector).use(initReactI18next).init({
        resources: {
            en: {
                translation: i18nEN
            },
            'zh-CN': {
                translation: i18nZH
            },
            'zh-TW': {
                translation: i18nZH
            },
            'zh': {
                translation: i18nZH
            },
            'zh-HK': {
                translation: i18nZH
            }
        },
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lng'
        },
        supportedLngs: ['zh', 'zh-CN', 'zh-TW', 'zh-HK', 'en'],
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });
    return (): i18n => {
        return i18next;
    };
};

export const getI18nInstance = initI18n();
export const t = getI18nInstance().t.bind(i18next);
