// import { useParams } from "react-router-dom";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import "./FooterBar.scss";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import { FooterBarProps } from "../../../types";
import { useTranslation } from "react-i18next";

function FooterBar(props: FooterBarProps) {
  const { t } = useTranslation();

  return (
    <AppBar position="fixed" color="secondary" className="itk-game-footerbar">
      <Tabs
        aria-label="game screen control"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          icon={<ArrowDownwardIcon />}
          label={t("GAME_BACK")}
          onClick={() => {
            setTimeout(() => {
              props.setShowGameScreen(false);
            }, 300);
          }}
        />
        <Tab
          icon={<SportsEsportsIcon />}
          label={t("GAME_PLAY")}
          onClick={() => {
            setTimeout(() => {
              props.setShowGameLaunchPad(true);
            }, 300);
          }}
        />
        <Tab
          icon={props.gameGuideVisible ? <InfoIcon /> : <HelpIcon />}
          label={props.gameGuideVisible ? t("GAME_INFO") : t("GAME_GUIDE")}
          onClick={() => {
            setTimeout(() => {
              props.setShowGameGuide(!props.gameGuideVisible);
            }, 300);
          }}
        />
      </Tabs>
    </AppBar>
  );
}

export default FooterBar;
