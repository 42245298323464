import "./Greeting.scss";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function Greeting() {
  const { t } = useTranslation();

  return (
    <div className="itw-content-greeting">
      <Typography color="textSecondary">{t("GAMELIST_SLOGAN")}</Typography>
    </div>
  );
}

export default Greeting;
