import "./GameScreen.scss";
import { Slide, Dialog } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React from "react";
import { Device, GameScreenProps } from "../../types";
import FooterBar from "./phone/FooterBar";
import GameLaunchPad from "../../common/GameLaunchPad";
import GameInfoPage from "../../common/GameInfoPage";
import GameGuide from "../../common/GameGuide";
import { getGameEntryById } from "../../util";
import { useTranslation } from "react-i18next";

const transition = React.forwardRef(function Transition(props, ref) {
  const history = useHistory();
  return (
    <Slide
      direction="up"
      ref={ref}
      {...(props as any)}
      onExited={() => history.push("/")}
    />
  );
});

function GameScreen(props: GameScreenProps) {
  const [showGameScreen, setShowGameScreen] = React.useState(true);
  const [showGameLaunchPad, setShowGameLaunchPad] = React.useState(false);
  const [showGameGuide, setShowGameGuide] = React.useState(false);
  const { i18n } = useTranslation();
  const gameEntry = getGameEntryById(props.gameId);

  let GameScreenContent;
  switch (props.device) {
    case Device.Phone:
      GameScreenContent = (
        <div
          className={
            showGameLaunchPad
              ? "itw-gamescreen-content-hidden"
              : "itw-gamescreen-content"
          }
        >
          <GameInfoPage
            visible={!showGameGuide}
            videoLinks={gameEntry.videoLinks}
            screenshots={gameEntry.screenshots}
            details={
              gameEntry.details[i18n.language]
                ? gameEntry.details[i18n.language]
                : gameEntry.details["en"]
            }
          />
          <GameGuide
            setShowGameGuide={setShowGameGuide}
            visible={showGameGuide}
            guide={
              gameEntry.guide[i18n.language]
                ? gameEntry.guide[i18n.language]
                : gameEntry.guide["en"]
            }
          />
          <FooterBar
            gameId={props.gameId}
            setShowGameScreen={setShowGameScreen}
            setShowGameLaunchPad={setShowGameLaunchPad}
            setShowGameGuide={setShowGameGuide}
            gameGuideVisible={showGameGuide}
          ></FooterBar>
        </div>
      );
      break;
    case Device.Desktop:
    default:
      GameScreenContent = (
        <div
          className={
            showGameLaunchPad
              ? "itw-gamescreen-content-hidden"
              : "itw-gamescreen-content"
          }
        >
          <GameInfoPage
            visible={!showGameGuide}
            videoLinks={gameEntry.videoLinks}
            screenshots={gameEntry.screenshots}
            details={
              gameEntry.details[i18n.language]
                ? gameEntry.details[i18n.language]
                : gameEntry.details["en"]
            }
          />
          <GameGuide
            setShowGameGuide={setShowGameGuide}
            visible={showGameGuide}
            guide={
              gameEntry.guide[i18n.language]
                ? gameEntry.guide[i18n.language]
                : gameEntry.guide["en"]
            }
          />
          <FooterBar
            gameId={props.gameId}
            setShowGameScreen={setShowGameScreen}
            setShowGameLaunchPad={setShowGameLaunchPad}
            setShowGameGuide={setShowGameGuide}
            gameGuideVisible={showGameGuide}
          ></FooterBar>
        </div>
      );
      break;
  }

  return (
    <Dialog
      fullScreen
      open={showGameScreen}
      TransitionComponent={transition as any}
    >
      {GameScreenContent}
      <GameLaunchPad
        gameId={props.gameId}
        showGameLaunchPad={showGameLaunchPad}
        setShowGameLaunchPad={setShowGameLaunchPad}
      ></GameLaunchPad>
    </Dialog>
  );
}

export default GameScreen;
