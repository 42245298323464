import Greeting from "../common/Greeting";
import "./GameGrid.scss";
import HeaderPadding from "../common/HeaderPadding";
import { Typography } from "@material-ui/core";
import { GameEntryList } from "../util";
import GameCard from "../common/GameCard";
import { Device, GameGridProps } from "../types";
import { useTranslation } from "react-i18next";

function GameGrid(props: GameGridProps) {
  const { t } = useTranslation();

  return (
    <div className="itw-gamegrid">
      <HeaderPadding />
      {props.device === Device.Phone ? <></> : <Greeting />}

      <div className="itw-gamegrid-gamelist">
        <div className="itw-gamegrid-responsive-grid">
          {GameEntryList.map((gameEntry) => {
            return (
              <div className="itw-gamegrid-responsive-grid-item">
                <GameCard
                  id={gameEntry.id}
                  tags={gameEntry.tags}
                  name={gameEntry.name}
                  desc={gameEntry.desc}
                  author={gameEntry.author}
                  authorAvatar={gameEntry.authorAvatar}
                  videoLinks={gameEntry.videoLinks}
                  screenshots={gameEntry.screenshots}
                  guide={gameEntry.guide}
                  details={gameEntry.details}
                ></GameCard>
              </div>
            );
          })}
        </div>

        <div className="itw-gamegrid-label-moregame">
          <Typography
            variant="button"
            display="block"
            color="textSecondary"
            component="p"
          >
            {t("GAMELIST_SHORTAGE")}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default GameGrid;
