import { GameListProps } from "../../types";
import Header from "../../common/Header";
import Bottom from "../../common/Bottom";
import { useParams } from "react-router-dom";
import GameScreen from "../game/GameScreen";
import GameGrid from "../../common/GameGrid";

function GameList(props: GameListProps) {
  let { id } = useParams() as any;

  return (
    <>
      <Header device={props.device}></Header>
      <GameGrid device={props.device} />
      <Bottom />
      {id && <GameScreen gameId={id} device={props.device} />}
    </>
  );
}

export default GameList;
