import "./Bottom.scss";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Link } from "@material-ui/core";

function Bottom() {
  return (
    <div className="itw-bottom">
      <div>
        <span>
          <IconButton aria-label="delete" color="inherit">
            <FacebookIcon />
          </IconButton>
        </span>
        <span>
          <Button aria-label="delete" color="inherit">
            About
          </Button>
        </span>
        <span>
          <Button aria-label="delete" color="inherit">
            Contact Us
          </Button>
        </span>
      </div>
      <div>
        <span>
          <Link color="inherit">Terms of Use</Link>
        </span>
        <span> · </span>
        <span>
          <Link color="inherit">Privacy</Link>
        </span>
        <span> · </span>
        <span>
          <Link color="inherit">Cookies</Link>
        </span>
      </div>
    </div>
  );
}

export default Bottom;
