import "./Header.scss";
import SecurityIcon from "@material-ui/icons/Security";
// import MenuIcon from "@material-ui/icons/Menu";
import {
  // IconButton,
  // Tab,
  // Tabs,
  // SvgIcon,
  Typography,
  Toolbar,
  AppBar,
} from "@material-ui/core";
import {
  // Device,
  HeaderProps,
} from "../types";

function Header(props: HeaderProps) {
  return (
    <AppBar position="fixed" className="itw-header">
      <Toolbar variant="dense">
        {/* {props.device === Device.Phone && (
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        )} */}
        <SecurityIcon color="inherit" />
        {/* { iTotalWarIcon } */}
        <Typography
          variant="h6"
          className="itw-header-sitename"
          color="inherit"
        >
          iTotalWar.com
        </Typography>

        {/* {props.device !== Device.Phone && (
          <Tabs aria-label="simple tabs example">
            <Tab label="Featured Games" />
            <Tab label="Find a game" />
            <Tab label="No Sign up" />
          </Tabs>
        )} */}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
