import "./GameCard.scss";
import {
  Card,
  Chip,
  Typography,
  Avatar,
  Link,
  ButtonBase,
} from "@material-ui/core";
import placeholderImage from "../resources/placeholder_image.svg";
import placeholderAvatar from "../resources/placeholder_avatar.jpeg";
import MouseIcon from "@material-ui/icons/Mouse";
import LowPolyIcon from "@material-ui/icons/Terrain";
import StrategyIcon from "@material-ui/icons/Map";
import TowerDefenseIcon from "@material-ui/icons/LocationCity";
import TotalWarIcon from "@material-ui/icons/Security";
import DefaultTagIcon from "@material-ui/icons/LocalOffer";
import { useTranslation } from "react-i18next";
import { Game } from "../types";
import { useHistory } from "react-router-dom";
import React from "react";

function GameCard(props: Game) {
  const getTagIcon = (tag: string): any => {
    switch (tag) {
      case "TAG_CLICKER":
        return <MouseIcon />;
      case "TAG_LOWPOLY":
        return <LowPolyIcon />;
      case "TAG_STRATEGY":
        return <StrategyIcon />;
      case "TAG_TOWERDEFENSE":
        return <TowerDefenseIcon />;
      case "TAG_ITOTALWAR":
        return <TotalWarIcon />;
      default:
        return <DefaultTagIcon />;
    }
  };

  const history = useHistory();
  const [showAuthorLabel, setShowAuthorLabel] = React.useState(false);
  const { i18n, t } = useTranslation();

  return (
    <Card className="itw-gamecard">
      <div className="itw-gamecard-container">
        <div className="itw-gamecard-media">
          <ButtonBase
            focusRipple
            key={props.id}
            style={{
              width: "350px",
              height: "180px",
            }}
            onClick={() => {
              setTimeout(() => {
                history.push(`/game/${props.id}`);
              }, 300);
            }}
          >
            <span
              style={{
                backgroundImage: `url(${placeholderImage})`,
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: "cover",
                backgroundPosition: "center 40%",
              }}
            />
          </ButtonBase>
        </div>
        <div
          className={"itw-gamecard-author-container"}
          onClick={() => setShowAuthorLabel(!showAuthorLabel)}
        >
          <Typography
            color="textPrimary"
            variant="body2"
            className={
              showAuthorLabel
                ? "itw-gamecard-author-name"
                : "itw-gamecard-author-name-hidden"
            }
          >
            {props.author}
          </Typography>

          <Avatar
            alt={props.author}
            src={placeholderAvatar}
            className="itw-gamecard-author-avatar"
          />
        </div>

        <div className="itw-gamecard-info">
          <div className="itw-gamecard-info-text">
            <Link
              display="block"
              href="#"
              variant="body2"
              color="textSecondary"
              className="itw-gamecard-info-title"
              onClick={() => {
                setTimeout(() => {
                  history.push(`/game/${props.id}`);
                }, 300);
              }}
            >
              {props.name[i18n.language]
                ? props.name[i18n.language]
                : props.name["en"]}
            </Link>

            <Typography
              variant="body1"
              display="block"
              color="textSecondary"
              component="p"
              className="itw-gamecard-info-desc"
            >
              {props.desc[i18n.language]
                ? props.desc[i18n.language]
                : props.desc["en"]}
            </Typography>
          </div>
          <div className="itw-gamecard-info-tags">
            {props.tags.map((tag) => (
              <Chip
                size="small"
                icon={getTagIcon(tag)}
                label={t(tag)}
                color="secondary"
                className="itw-gamecard-info-tag"
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default GameCard;
