import "./VideoContainer.scss";
import { useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { Checkbox, SvgIcon } from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { VideoContainerProps, VideoPlatform } from "../types";

function VideoContainer(props: VideoContainerProps) {
  const [isVideoContainerReady, setVideoContainerReady] = useState(false);
  const [selectedVideoPlatform, setSelectedVideoPlatform] = useState(
    VideoPlatform.youtube
  );
  const BilibiliIcon = (
    <SvgIcon>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M7.172 2.757L10.414 6h3.171l3.243-3.242a1 1 0 0 1 1.415 1.415l-1.829 1.827L18.5 6A3.5 3.5 0 0 1 22 9.5v8a3.5 3.5 0 0 1-3.5 3.5h-13A3.5 3.5 0 0 1 2 17.5v-8A3.5 3.5 0 0 1 5.5 6h2.085L5.757 4.171a1 1 0 0 1 1.415-1.415zM18.5 8h-13a1.5 1.5 0 0 0-1.493 1.356L4 9.5v8a1.5 1.5 0 0 0 1.356 1.493L5.5 19h13a1.5 1.5 0 0 0 1.493-1.356L20 17.5v-8A1.5 1.5 0 0 0 18.5 8zM8 11a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1zm8 0a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1z" />
    </SvgIcon>
  );

  return (
    <div className="itw-video-container-root">
      <div
        className={
          isVideoContainerReady
            ? "itw-video-container"
            : "itw-video-container-hidden"
        }
      >
        <iframe
          width="100%"
          height="100%"
          src={props.videoLinks[selectedVideoPlatform]}
          title={selectedVideoPlatform}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onLoad={() => setVideoContainerReady(true)}
          className="itw-video-frame"
        ></iframe>
      </div>

      <div
        className={
          isVideoContainerReady
            ? "itw-video-container itw-video-skeleton-hidden"
            : "itw-video-container"
        }
      >
        <div className="itw-video-frame">
          <div className="itw-video-skeleton-group">
            <Skeleton variant="text" color="primary" height={20} />
            <Skeleton variant="circle" color="primary" width={40} height={40} />
            <Skeleton variant="rect" color="primary" height={110} />
          </div>
        </div>
      </div>

      <div className="itw-video-platforms">
        <Checkbox
          icon={<YouTubeIcon />}
          color="primary"
          checkedIcon={<YouTubeIcon />}
          name={`checkbox-${VideoPlatform.youtube}`}
          checked={selectedVideoPlatform === VideoPlatform.youtube}
          onChange={() => {
            if (selectedVideoPlatform === VideoPlatform.youtube) return;
            setVideoContainerReady(false);
            setSelectedVideoPlatform(VideoPlatform.youtube);
          }}
        />

        <Checkbox
          icon={BilibiliIcon}
          color="primary"
          checkedIcon={BilibiliIcon}
          name={`checkbox-${VideoPlatform.bilibili}`}
          checked={selectedVideoPlatform === VideoPlatform.bilibili}
          onChange={() => {
            if (selectedVideoPlatform === VideoPlatform.bilibili) return;
            setVideoContainerReady(false);
            setSelectedVideoPlatform(VideoPlatform.bilibili);
          }}
        />
      </div>
    </div>
  );
}

export default VideoContainer;
