import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import { GameLaunchPadProps } from "../types";
import "./GameLaunchPad.scss";
import StopIcon from "@material-ui/icons/Stop";
import React from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

function GameLaunchPad(props: GameLaunchPadProps) {
  const { t } = useTranslation();

  const [showExitGameDialog, setShowExitGameDialog] = React.useState(false);
  const [isStartDragging, setStartDragging] = React.useState(false);

  const handleDragStart = () => {};
  const handleDragging = () => {
    setStartDragging(true);
  };
  const handleDragEnd = () => {
    if (!isStartDragging) {
      // Clicking no dragging
      setTimeout(() => setShowExitGameDialog(true), 300);
    }
    setStartDragging(false);
  };

  return (
    <>
      <iframe
        id="itw-gamelaunchpad"
        title={`gamelaunchpad-${props.gameId}`}
        src={
          props.showGameLaunchPad
            ? document.location.host.includes(":3001")
              ? `//${document.location.hostname}:3000/`
              : `//static.itotalwar.com/${props.gameId}/index.html`
            : "about:blank"
        }
        className={
          props.showGameLaunchPad
            ? "itw-gamelaunchpad"
            : "itw-gamelaunchpad-hidden"
        }
      ></iframe>
      <Draggable
        bounds="parent"
        onStart={handleDragStart}
        onDrag={handleDragging}
        onStop={handleDragEnd}
      >
        <Fab
          variant="circular"
          size="small"
          aria-label="close game launch pad"
          className={
            props.showGameLaunchPad
              ? "itw-gamelaunchpad-back"
              : "itw-gamelaunchpad-back-hidden"
          }
          color="primary"
        >
          <StopIcon />
        </Fab>
      </Draggable>

      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation exit game"
        open={showExitGameDialog}
      >
        <DialogTitle id="confirmation-dialog-title">
          {t("GAMEPAD_TITLE_EXIT")}
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{t("GAMEPAD_TEXT_EXIT")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowExitGameDialog(false)}
            color="default"
          >
            {t("GAMEPAD_BTN_CANCEL")}
          </Button>
          <Button
            onClick={() => {
              setShowExitGameDialog(false);
              props.setShowGameLaunchPad(false);
            }}
            color="primary"
          >
            {t("GAMEPAD_BTN_EXIT")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GameLaunchPad;
