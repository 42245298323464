import { Switch, Route } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./App.scss";
import { Device } from "./types";
import GameList from "./screens/gamelist/GameList";
import ProdInfo from "./screens/prod-info/ProdInfo";
import {
  createTheme,
  // responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#268785",
      main: "#268785",
      light: "#268785",
      contrastText: "#FFF",
    },
    secondary: {
      dark: "#A5DEE4",
      main: "#A5DEE4",
      light: "#A5DEE4",
      contrastText: "rgba(0, 0, 0, 0.54)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.85)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    // fontFamily: ["ZCOOL KuaiLe", "cursive"].join(","),
  },
});

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  // const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  let deviceType = Device.Desktop;
  if (isBigScreen && isDesktopOrLaptop) {
    deviceType = Device.Desktop;
  } else if (isTabletOrMobileDevice) {
    deviceType = Device.Phone;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Switch>
          <Route exact path="/" children={<GameList device={deviceType} />} />
          <Route path="/game/:id" children={<GameList device={deviceType} />} />
          <Route exact path="/about" children={<ProdInfo />} />
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
