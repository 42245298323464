import { GameInfoPageProps } from "../types";
import "./GameInfoPage.scss";
import VideoContainer from "./VideoContainer";

function GameInfoPage(props: GameInfoPageProps) {
  return (
    <div className={props.visible ? "itw-gameinfo" : "itw-gameinfo-hidden"}>
      <VideoContainer videoLinks={props.videoLinks} />
      <pre className={"itw-gameinfo-detail"}>{props.details}</pre>
    </div>
  );
}

export default GameInfoPage;
